import * as React from 'react';
// import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { HomeSwiper } from '@/components/home/swiper';
import { Supporters } from '@/components/supporters';
// import { Faqs } from '@/components/faq';

export function Home() {
  // const faqs = useSelector((state) => state.faq.items);

  const { t } = useTranslation();

  return (
    <>
      <HomeSwiper />

      <section className="tf-section tf-about pb-mobie">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div
                className="content-about mobie-40"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <div className="tf-title st2">
                  <p className="h8 sub-title">{t('about_us')}</p>
                  <h4 className="title">{t('we_are')} FRAKTIQ</h4>
                </div>
                <p>{t('we_are_desc')}</p>
              </div>
            </div>
            <div className="col-md-7 text-center">
            </div>
          </div>
        </div>
      </section>

      <section className="tf-section tf-roadmap">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                className="tf-title m-b30"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <p className="h8 sub-title">{t('roadmap')}</p>
                <h4 className="title">FRAKTIQ {t('timeline')}</h4>
              </div>
            </div>
            <div className="col-md-12">
              <div className="roadmap d-md-none">
                <div className="box box-left">
                  <div
                    className="rm-box style"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                  >
                    <div className="corner-box">
                      <h5>1.</h5>
                      <ul>
                        <li>{t('roadmap_step_1')}</li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="rm-box"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                  >
                    <div className="corner-box">
                      <h5>2.</h5>
                      <ul>
                        <li>{t('roadmap_step_2')}</li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="rm-box"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                  >
                    <div className="corner-box">
                      <h5>3.</h5>
                      <ul>
                        <li>{t('roadmap_step_3')}</li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="rm-box"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                  >
                    <div className="corner-box">
                      <h5>4.</h5>
                      <ul>
                        <li>{t('roadmap_step_4')}</li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="rm-box"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                  >
                    <div className="corner-box">
                      <h5>5.</h5>
                      <ul>
                        <li>{t('roadmap_step_5')}</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="box box-right">
                  <div
                    className="rm-box style"
                    data-aos="fade-left"
                    data-aos-duration="1200"
                  >
                    <div className="corner-box">
                      <h5>6.</h5>
                      <ul>
                        <li>{t('roadmap_step_6')}</li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="rm-box"
                    data-aos="fade-left"
                    data-aos-duration="1200"
                  >
                    <div className="corner-box">
                      <h5>7.</h5>
                      <ul>
                        <li>{t('roadmap_step_7')}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="roadmap d-none d-md-flex">
                <div className="box box-left">
                  <div
                    className="rm-box style"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                  >
                    <div className="corner-box">
                      <h5>1.</h5>
                      <ul>
                        <li>{t('roadmap_step_1')}</li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="rm-box"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                  >
                    <div className="corner-box">
                      <h5>3.</h5>
                      <ul>
                        <li>{t('roadmap_step_3')}</li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="rm-box"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                  >
                    <div className="corner-box">
                      <h5>5.</h5>
                      <ul>
                        <li>{t('roadmap_step_5')}</li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="rm-box"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                  >
                    <div className="corner-box">
                      <h5>7.</h5>
                      <ul>
                        <li>{t('roadmap_step_7')}</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="box box-right">
                  <div
                    className="rm-box style"
                    data-aos="fade-left"
                    data-aos-duration="1200"
                  >
                    <div className="corner-box">
                      <h5>2.</h5>
                      <ul>
                        <li>{t('roadmap_step_2')}</li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="rm-box"
                    data-aos="fade-left"
                    data-aos-duration="1200"
                  >
                    <div className="corner-box">
                      <h5>4.</h5>
                      <ul>
                        <li>{t('roadmap_step_4')}</li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="rm-box"
                    data-aos="fade-left"
                    data-aos-duration="1200"
                  >
                    <div className="corner-box">
                      <h5>6.</h5>
                      <ul>
                        <li>{t('roadmap_step_6')}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="tf-section how-we-work">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                className="tf-title st2 m-b60"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <p className="h8 sub-title">{t('how_we_work')}</p>
                <h4 className="title m-b17">{t('become_our_member')}</h4>
                <p className="p-r60">{t('become_our_member_desc')}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Supporters />

      {/* <section className="tf-section faq">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                className="tf-title m-b60"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <p className="h8 sub-title">{t('faqs')}</p>
                <h4 className="title">{t('looking_for_answers')}</h4>
              </div>
            </div>
            <div className="col-md-12">
              <Faqs items={faqs} />
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}
