import { createSlice } from '@reduxjs/toolkit';

const faqs = [
  {
    title: 'fraktiq_faq_1',
    content: `fraktiq_faq_desc_1`,
  },
  {
    title: 'fraktiq_faq_2',
    content: `fraktiq_faq_desc_2`,
  },
  {
    title: 'fraktiq_faq_3',
    content: `fraktiq_faq_desc_3`,
  },
  {
    title: 'fraktiq_faq_4',
    content: 'fraktiq_faq_desc_4',
  },
  {
    title: 'fraktiq_faq_5',
    content: `fraktiq_faq_desc_5`,
  },
  {
    title: 'fraktiq_faq_6',
    content: `fraktiq_faq_desc_6`,
  },
  // {
  //   title: 'fraktiq_faq_7',
  //   content: `fraktiq_faq_desc_7`,
  // },
  {
    title: 'fraktiq_faq_8',
    content: `fraktiq_faq_desc_8`,
  },
  {
    title: 'fraktiq_faq_9',
    content: `fraktiq_faq_desc_9`,
  },
  {
    title: 'fraktiq_faq_10',
    content: `fraktiq_faq_desc_10`,
  },
  {
    title: 'fraktiq_faq_11',
    content: `fraktiq_faq_desc_11`,
  },
  {
    title: 'fraktiq_faq_12',
    content: 'fraktiq_faq_desc_12',
  },
  {
    title: 'fraktiq_faq_13',
    content: `fraktiq_faq_desc_13`,
  },
  {
    title: 'fraktiq_faq_14',
    content: `fraktiq_faq_desc_14`,
  },
  {
    title: 'fraktiq_faq_15',
    content: `fraktiq_faq_desc_15`,
  },
  {
    title: 'fraktiq_faq_16',
    content: `fraktiq_faq_desc_16`,
  },
];

const initialState = {
  items: faqs,
};

export const faqSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {},
});

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = faqSlice.actions;

export default faqSlice.reducer;
