import * as React from 'react';

import './styles.scss';

export function Privacy() {
  const content = `Fraktiq (“Fraktiq,” “we”, “us”, or “our”) is committed to protecting your privacy. We have prepared this Privacy Policy to describe to you our practices regarding the Personal Data (as defined below) we collect, use, and share in connection with the Fraktiq website other software provided on or in connection with our services, as described in our Terms of Service (collectively, the “Service”).<br><br>
  <div class="content-sub-title">Collection and Use of Non-Personal Information</div><br>
  We may use cookies on or in connection with our Site to maintain information about you. A cookie is a very small text document, which often includes an anonymous unique identifier. We use both session cookies and persistent cookies. A persistent cookie remains on your hard drive after you close your browser. Persistent cookies may be used by your browser on subsequent visits to the Site. Persistent cookies can be removed by following your web browser directions. A session cookie is temporary and disappears after you close your browser. When you visit our Site, our computer may ask your computer for permission to employ the use of a session or persistent cookie. Our Site will then send a cookie to your browser if your browser preferences allow it, but (to protect your privacy) your browser only permits our Site to access the cookies it has already sent to you and not the cookies sent to you by other websites. Many web browsers can be adjusted to inform you when a cookie has been sent to you and provide you with the opportunity to refuse that cookie. However, refusing a cookie may, in some cases, preclude you from using, or negatively impact the display or function of, our Site or certain areas or features of our Site.<br><br>
  Our servers automatically collect data about your Internet Protocol address when you visit us. When you request pages from our Site, our servers may log your IP Address and sometimes your domain name. Our server may also record the referring page that linked you to us (e.g., another website or a search engine); the pages you visit on our Site; the website you visit after this Site; other information about the type of web browser, computer, platform, related software and settings you are using; any search terms you have entered on this Site or a referral website; and other web usage activity and data logged by our web servers. Your IP address can also be used for moderation purposes, in order to limit the creation of multiple accounts and SPAM prevention.
  `;

  return (
    <section className="tf-section tf-about pb-mobie">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div
              className="content-about m-b60 mobie-40"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <div>
                <h4 className="title">Privacy Policy</h4>
              </div>
              <div>
                <h6 className="content-last-updated">
                  Last Updated: 1st July, 2022
                </h6>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div
              className="content-about m-b60 mobie-40"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
