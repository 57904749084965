import React from 'react';
import ReactDOM from 'react-dom/client';
import Modal from 'react-modal';

import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Wrap from './Wrap';
import './i18n';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import reportWebVitals from './reportWebVitals';

import { store } from '@/app/store';
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div className="header-fixed main">
    <React.StrictMode>
      <Provider store={store}>
        <HelmetProvider>
          <BrowserRouter>
            <Wrap />
          </BrowserRouter>
          <ToastContainer position="top-center" />
        </HelmetProvider>
      </Provider>
    </React.StrictMode>
  </div>
);

Modal.setAppElement('#root');

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
