import { useState } from 'react';
import { useSpring, animated } from 'react-spring';

export function Loading() {
  const [isActive] = useState(false);

  const props = useSpring({
    opacity: isActive ? 1 : 0,
    top: isActive ? '0' : '-100vh',
    from: {
      opacity: 1,
      top: '0',
      left: 0,
      position: 'absolute',
      zIndex: 3000,
    },
  });

  return (
    <animated.div style={props} className={`vh-100 vw-100 bg-white`}>
      <div className="preload-logo"></div>
    </animated.div>
  );
}
