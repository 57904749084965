import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  mintableQuantity: 0,
  showMintableQuantity: false,
};

export const baoyueVaseSlice = createSlice({
  name: 'baoyueVase',
  initialState,
  reducers: {
    setMintableQuantity: (state, action) => {
      let showMintableQuantity = false;
      let mintableQuantity = 0;
      if (action.payload) {
        showMintableQuantity = true;
        mintableQuantity = action.payload;
      }

      return {
        ...state,
        showMintableQuantity,
        mintableQuantity,
      };
    },
  },
});

export const { setMintableQuantity } = baoyueVaseSlice.actions;

export default baoyueVaseSlice.reducer;
