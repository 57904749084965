import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter,
  faYoutube,
  faDiscord,
  faFacebook,
  faLinkedin,
  faInstagram,
  faTelegram,
} from '@fortawesome/free-brands-svg-icons';

export default function Footer() {
  const { t } = useTranslation();

  return (
    <footer id="footer">
      <div className="footer-main">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/fraktiq.appspot.com/o/website%2Fimages%2Fbg-ft.png?alt=media&token=aa2f1200-3a4a-4ff0-86ba-3192c161b10c"
          alt=""
          className="bg1"
        />
        <img
          src="https://firebasestorage.googleapis.com/v0/b/fraktiq.appspot.com/o/website%2Fimages%2Fbg-ft2.png?alt=media&token=81ed8274-e133-4ce1-bc05-5f6a517631ed"
          alt=""
          className="bg2 d-none d-md-block"
        />
        <div className="container">
          <ul className="widget-social">
            <li>
              <a
                href="https://www.youtube.com/channel/UCGue4u066SVBJPHkuVrub8Q"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faYoutube} color="white" size="lg" />
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/fraktiq"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faTwitter} color="white" size="lg" />
              </a>
            </li>
            <li>
              <a
                href="https://discord.gg/JtFHugHPyw"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faDiscord} color="white" size="lg" />
              </a>
            </li>
            {/* <li>
              <a
                href="https://www.facebook.com/fraktiq/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faFacebook} color="white" size="lg" />
              </a>
            </li> */}
            <li>
              <a
                href="https://www.linkedin.com/company/fraktiq/?miniCompanyUrn=urn%3Ali%3Afs_miniCompany%3A82516154"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} color="white" size="lg" />
              </a>
            </li>
            {/* <li>
              <a
                href="https://www.instagram.com/fraktiq/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} color="white" size="lg" />
              </a>
            </li>
            <li>
              <a href="https://t.me/fraktiq/" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faTelegram} color="white" size="lg" />
              </a>
            </li> */}
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="wrap-fx">
            <div className="Copyright">
              <p>
                FRAKTIQ {new Date().getFullYear()} - {t('all_rights_reserved')}
              </p>
            </div>
            <ul className="list">
              <li>
                <Link to="privacy">{t('privacy_policy')}</Link>
              </li>

              <li>
                <Link to="terms">{t('terms_conditions')}</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
