import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

import './styles.scss';

import { TeamMemberItem } from '@/components/team/memberItem';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export function Team() {
  const { t } = useTranslation();

  const teamMembers = useSelector((state) => state.team.items);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [teamMember, setTeamMember] = useState({
    key: 'loading',
    name: 'loading',
    description: 'loading',
    image: 'loading',
  });

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const onMemberClick = (member) => {
    setTeamMember(member);
    setModalIsOpen(true);
  };

  return (
    <section className="tf-section team-detail">
      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <section className="tf-section team-detail ">
          <div className="container">
            <div className="row">
              <div className="offset-lg-2 col-lg-2 col-md-5">
                <div
                  className="image-details"
                  data-aos="fade-right"
                  data-aos-duration="800"
                >
                  <img src={t(teamMember.image)} alt={t(teamMember.name)} />
                </div>
              </div>
              <div className="col-xl-7 col-lg-7 col-md-7">
                <div
                  className="info-detail"
                  data-aos="fade-left"
                  data-aos-duration="800"
                >
                  <h4 className="name">{t(teamMember.name)}</h4>
                  <div className="box">
                    <p>{t(teamMember.description)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Modal>
      <div className="container">
        <div className="row mb-4">
          <div className="col-md-12">
            <div
              className="tf-title m-b60 aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <h4 className="title">{t('fraktiq_team')}</h4>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          {teamMembers.map((member) => {
            return (
              <TeamMemberItem
                key={member.key}
                member={member}
                onClick={() => onMemberClick(member)}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
}
