import * as React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

function Item({ object, isBenefit }) {
  return (
    <div className={`item corner-box faq-box membership-item d-flex`}>
      <div className="me-4 fs-4">•</div>
      <div className={`h7 toggle-title benefit-item`}>{object.title}</div>
    </div>
  );
}

function Items({ items, isBenefit }) {
  return (
    <div data-aos="fade-up" data-aos-duration="800">
      {items &&
        items.length &&
        items.map(function (object, i) {
          return <Item object={object} key={i} isBenefit={isBenefit} />;
        })}
    </div>
  );
}

export function Membership() {
  const { t } = useTranslation();

  const benefits = [
    {
      title: t('fraktiq_benefit_1'),
    },
    {
      title: t('fraktiq_benefit_2'),
    },
    {
      title: t('fraktiq_benefit_3'),
    },
    {
      title: t('fraktiq_benefit_4'),
    },
    {
      title: t('fraktiq_benefit_5'),
    },
    {
      title: t('fraktiq_benefit_6'),
    },
  ];

  const missions = [
    {
      title: t('fraktiq_E-marketplace_1'),
    },
    {
      title: t('fraktiq_E-marketplace_2'),
    },
    {
      title: t('fraktiq_E-marketplace_3'),
    },
    {
      title: t('fraktiq_E-marketplace_4'),
    },
    {
      title: t('fraktiq_E-marketplace_5'),
    },
    {
      title: t('fraktiq_E-marketplace_6'),
    },
  ];
  return (
    <>
      <section className="tf-section tf-about style2 pb-mobie missions">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                className="content-about m-b60 mobie-40"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <div className="tf-title">
                  <h4 className="title">{t('services')}</h4>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div
                className="content-about m-b60 mobie-40"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <h6 className="benefit-title">{t('benefits')}</h6>
              </div>
            </div>
            <div className="col-md-12 flat-accordion">
              <div
                className="wrap-about style2 m-b48"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <Items items={benefits} isBenefit={true} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
