import * as React from 'react';
import { useTranslation } from 'react-i18next';

export function Roadmap() {
  const { t } = useTranslation();

  return (
    <>
      <section className="tf-section tf-roadmap">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                className="tf-title m-b30"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <h4 className="title">FRAKTIQ {t('roadmap')}</h4>
              </div>
            </div>
            <div className="col-md-12">
              <div className="roadmap d-md-none">
                <div className="box box-left">
                  <div
                    className="rm-box style"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                  >
                    <div className="corner-box">
                      <h5>1.</h5>
                      <ul>
                        <li>{t('roadmap_step_1')}</li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="rm-box"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                  >
                    <div className="corner-box">
                      <h5>2.</h5>
                      <ul>
                        <li>{t('roadmap_step_2')}</li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="rm-box"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                  >
                    <div className="corner-box">
                      <h5>3.</h5>
                      <ul>
                        <li>{t('roadmap_step_3')}</li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="rm-box"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                  >
                    <div className="corner-box">
                      <h5>4.</h5>
                      <ul>
                        <li>{t('roadmap_step_4')}</li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="rm-box"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                  >
                    <div className="corner-box">
                      <h5>5.</h5>
                      <ul>
                        <li>{t('roadmap_step_5')}</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="box box-right">
                  <div
                    className="rm-box style"
                    data-aos="fade-left"
                    data-aos-duration="1200"
                  >
                    <div className="corner-box">
                      <h5>6.</h5>
                      <ul>
                        <li>{t('roadmap_step_6')}</li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="rm-box"
                    data-aos="fade-left"
                    data-aos-duration="1200"
                  >
                    <div className="corner-box">
                      <h5>7.</h5>
                      <ul>
                        <li>{t('roadmap_step_7')}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="roadmap d-none d-md-flex">
                <div className="box box-left">
                  <div
                    className="rm-box style"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                  >
                    <div className="corner-box">
                      <h5>1.</h5>
                      <ul>
                        <li>{t('roadmap_step_1')}</li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="rm-box"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                  >
                    <div className="corner-box">
                      <h5>3.</h5>
                      <ul>
                        <li>{t('roadmap_step_3')}</li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="rm-box"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                  >
                    <div className="corner-box">
                      <h5>5.</h5>
                      <ul>
                        <li>{t('roadmap_step_5')}</li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="rm-box"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                  >
                    <div className="corner-box">
                      <h5>7.</h5>
                      <ul>
                        <li>{t('roadmap_step_7')}</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="box box-right">
                  <div
                    className="rm-box style"
                    data-aos="fade-left"
                    data-aos-duration="1200"
                  >
                    <div className="corner-box">
                      <h5>2.</h5>
                      <ul>
                        <li>{t('roadmap_step_2')}</li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="rm-box"
                    data-aos="fade-left"
                    data-aos-duration="1200"
                  >
                    <div className="corner-box">
                      <h5>4.</h5>
                      <ul>
                        <li>{t('roadmap_step_4')}</li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="rm-box"
                    data-aos="fade-left"
                    data-aos-duration="1200"
                  >
                    <div className="corner-box">
                      <h5>6.</h5>
                      <ul>
                        <li>{t('roadmap_step_6')}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
