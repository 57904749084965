import * as React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import App from './App';

import { ScrollToTop } from '@/components/scrollToTop';
import { Loading } from '@/components/loading';

import { Home } from '@/pages/home';
import { About } from '@/pages/about';
import { Team } from '@/pages/team';
import { Roadmap } from '@/pages/roadmap';
// import { Faq } from '@/pages/faq';
import { Membership } from '@/pages/membership';
import { Privacy } from '@/pages/privacy';
import { Terms } from '@/pages/terms';

function Wrap() {
  return (
    <>
      <Loading />
      <ScrollToTop />
      <Routes>
        <Route path="/:lang" element={<App />}>
          <Route path="" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="team" element={<Team />} />
          <Route path="roadmap" element={<Roadmap />} />
          {/* <Route path="faq" element={<Faq />} /> */}
          <Route path="services" element={<Membership />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="terms" element={<Terms />} />
        </Route>
        <Route path="*" element={<Navigate to="/en" replace />} />
      </Routes>
    </>
  );
}

export default Wrap;
