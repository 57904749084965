import React, { useState } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

import 'swiper/css';

import './styles.scss';

export function Supporters() {
  const { t } = useTranslation();
  const [swiperRef, setSwiperRef] = useState(null);

  const supportingParties = [
    // {
    //   name: 'Delfino (德富龍)',
    //   url: 'http://www.delfino.hk/',
    //   img: 'https://firebasestorage.googleapis.com/v0/b/fraktiq.appspot.com/o/website%2Fimages%2Fsupporters%2F20200422174451125112.png?alt=media&token=0067adab-e3dc-4c6a-b95d-b95818d3d881',
    // },
    {
      name: 'Royal England',
      url: 'https://www.royalenglandsdb.com/zh-hk/home.html',
      img: 'https://firebasestorage.googleapis.com/v0/b/fraktiq.appspot.com/o/website%2Fimages%2Fsupporters%2FRoyal_England.png?alt=media&token=52d3dcc3-138c-4225-a0b7-12917df98b79',
    },
    {
      name: 'Perilli & Ho Solicitors (UK)',
      url: 'http://perilliho.com',
      img: 'https://firebasestorage.googleapis.com/v0/b/fraktiq.appspot.com/o/website%2Fimages%2Fsupporters%2FPerilli%20%26%20Ho%20Solicitors.png?alt=media&token=ed5bb01d-d6dd-4df1-9cc3-c99ec47bd7c8',
    },
    {
      name: 'Kaisun Trust & Trustee Services Company Ltd.',
      url: 'http://www.kaisuntrust.com/',
      img: 'https://firebasestorage.googleapis.com/v0/b/fraktiq.appspot.com/o/website%2Fimages%2Fsupporters%2FT%26T-logo-Vertical-Color-CMYK.webp?alt=media&token=90f59c9d-5d4f-4c5f-863c-d3e00664be0d',
    },
    {
      name: 'Solarr',
      url: 'https://www.solarr.xyz/',
      img: 'https://firebasestorage.googleapis.com/v0/b/fraktiq.appspot.com/o/website%2Fimages%2Fsupporters%2Fsolarr_logo.png?alt=media&token=06406f86-cd4e-4f09-bbcd-80cad9e30912',
    },
    {
      name: 'Elite Art Innovation',
      img: 'https://firebasestorage.googleapis.com/v0/b/fraktiq.appspot.com/o/website%2Fimages%2Fsupporters%2Feai.png?alt=media&token=f0af1fb5-b165-4925-beca-8b97f5dd6045',
    },
    {
      name: 'Syndicate Capital',
      url: 'https://www.syndicatecapital.com/',
      img: 'https://firebasestorage.googleapis.com/v0/b/fraktiq.appspot.com/o/website%2Fimages%2Fsupporters%2FSyndicate-Capital.png?alt=media&token=9a191263-ec1d-427b-b6f9-3f67efbfe630',
    },
    {
      name: 'UBP',
      url: 'https://www.ubpgroup.org/',
      img: 'https://firebasestorage.googleapis.com/v0/b/fraktiq.appspot.com/o/website%2Fimages%2Fsupporters%2Fubp.jpg?alt=media&token=e6358de0-ac96-4ad2-a4d9-ef7e5c9f397e',
    },
    {
      name: 'Titans Block',
      url: 'https://titansblock.com/',
      img: 'https://firebasestorage.googleapis.com/v0/b/fraktiq.appspot.com/o/website%2Fimages%2Fsupporters%2Ftitans.png?alt=media&token=7e86017e-6662-4124-b928-10b897805151',
    },
    {
      name: 'Artaeum',
      img: 'https://firebasestorage.googleapis.com/v0/b/fraktiq.appspot.com/o/website%2Fimages%2Fsupporters%2Fartaeum.png?alt=media&token=14df3062-c2fd-484a-8d9a-977277315cd0',
    },
    {
      name: '有明堂',
      url: 'http://www.mygallery.com.hk/',
      img: 'https://firebasestorage.googleapis.com/v0/b/fraktiq.appspot.com/o/website%2Fimages%2Fsupporters%2F%E6%9C%89%E6%98%8E%E5%A0%82.png?alt=media&token=1f28d169-f98c-48bd-bfc0-8bad8c35d3c3',
    },
  ];

  const slideNext = () => {
    swiperRef.slideNext();
  }

  const slidePrev = () => {
    swiperRef.slidePrev();
  }

  return (
    <section className="tf-section partner bg-st1 pb-mobie-40">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div
              className="tf-title"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <p className="h8 sub-title">{t('partners')}</p>
              <h4 className="title">{t('supporting_parties')}</h4>
            </div>
          </div>
          <div className="col-md-12">
            <div className="item-parner swiper-container">
              <div className="swiper-prev" onClick={slidePrev}>
                <FontAwesomeIcon icon={faAngleLeft} color={'#a68149'} size="2x" />
              </div>
              <div className="swiper-next" onClick={slideNext}>
                <FontAwesomeIcon icon={faAngleRight} color={'#a68149'} size="2x" />
              </div>
              <div
                className="item-parner swiper-wrap"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <Swiper
                  onSwiper={setSwiperRef}
                  spaceBetween={30}
                  slidesPerView={2}
                  breakpoints={{
                    600: {
                      slidesPerView: 3,
                    },
                    750: {
                      slidesPerView: 4,
                    },
                    991: {
                      slidesPerView: 5,
                    },
                  }}
                  loop
                >
                  {supportingParties.map((party, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <div className="supporter-image image">
                          <a href={party.url} target="_blank" rel="noreferrer">
                            <img src={party.img} alt={party.name} />
                          </a>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
