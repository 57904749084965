import { configureStore } from '@reduxjs/toolkit';
import faqSlice from '@/app/features/faq/faqSlice';
import teamSlice from '@/app/features/team/teamSlice';
import baoyueVaseSlice from '@/app/features/artifacts/baoyueVaseSlice';

export const store = configureStore({
  reducer: {
    faq: faqSlice,
    team: teamSlice,
    baoyueVase: baoyueVaseSlice,
  },
});
