import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { useTranslation } from 'react-i18next';

import 'swiper/css';
import 'swiper/css/navigation';

import { Link } from 'react-router-dom';

export function HomeSwiper() {
  const { t } = useTranslation();

  return (
    <section className="page-title">
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        modules={[Navigation]}
        navigation={{
          nextEl: '.btn-next-main',
          prevEl: '.btn-prev-main',
        }}
      >
        <SwiperSlide>
          <div className="slider-st1">
            <div className="overlay"></div>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="box-slider">
                    <div className="content-box">
                      <h1 className="title">{t('home_tagline')}</h1>
                      <p className="sub-title">{t('home_tagline2')}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <div className="btn-next-main">
        <i className="far fa-angle-right"></i>
      </div>
      <div className="btn-prev-main">
        <i className="far fa-angle-left"></i>
      </div>
    </section>
  );
}
