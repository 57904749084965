import * as React from 'react';

// import './styles.scss';

export function Terms() {
  const content = `General
  These General Terms and Conditions ("Agreement") govern the terms by which you may use and access the Fraktiq (the "Site") and/or any services provided therein. By accessing or using the Site and any service provided through the Site (the "Service"), you hereby represent, warrant and undertake that you have read, understood, and agree to be bound by the terms of this Agreement thereby making them a binding agreement between you and us, whether or not you are a registered user of our Site.<br><br>
  This Agreement applies to each visitor, user, and others who access or use the Site and/or Service ("User(s)").<br><br>
  <div class="content-sub-title">Data Protection</div><br>
  The protection of your personal information is very important to us. Therefore, we take appropriate actions to protect personal information of Users collected, stored or used by us. Before agreeing to this Agreement, please read our Privacy Policy to understand how your personal information is handled when accessing or using our internet services, which such Privacy Policy is hereby incorporated by reference into this Agreement.<br><br>
  <div class="content-sub-title">Proprietary Rights</div><br>
  All Content published by Fraktiq on the Site (apart from User-submitted Content), including, without limitation, images, photographs, graphics, animations, videos, audio and text (the "Site Content") are owned by Fraktiq and/or its licensors and is protected by copyright and other intellectual property or other proprietary rights. You hereby acknowledge that by using the Site and any Service you obtain no rights in the Site Content, or any part thereof. Copying and/or amending all or part of the Site Content, particularly for commercial or advertising use requires the prior written permission of Fraktiq. The making or amending of copies (either saved or printed) of opinions, reviews or other statements for personal and non-commercial use is permitted provided that legends or other references will not be falsified or deleted. Any other copying, amending, distribution or publication of Site Content without the prior written permission of Fraktiq is prohibited.<br><br>
  <div class="content-sub-title">Protection of trademarks</div><br>
  The "Fraktiq" marks and logos are trademarks. The use or the display of these trademarks without explicit written permission of Fraktiq is prohibited. Nothing on the Site should be construed as granting, by implication, estoppel, or otherwise, any right or license to use any of the “Fraktiq” marks without the prior explicit written consent of Fraktiq.<br><br>
  <div class="content-sub-title">Links to Other Websites</div><br>
  The Site may contain links and references to other websites. We may, from time to time, at our sole discretion, add or remove links to other websites. These links are provided for your convenience, and access to any such websites is at your own risk. You are encouraged to review the terms of use, privacy policy, and other policies or disclaimers provided on these websites prior to any use thereof. We do not review, approve, monitor, endorse, warrant, or make any representation with respect to such websites. In no event will we be responsible for the information contained in such websites, their practices or for your use of or inability to use such websites. You explicitly relieve us from any and all liability arising from your use of any third-party website.<br><br>
  <div class="content-sub-title">Restriction of Liability</div><br>
  Fraktiq uses reasonable efforts to ensure that the information available on the Site is accurate at all times. However, we cannot guarantee that such information will be fault-free and we cannot be responsible for services offered by us as agents for third parties or for any aspect of the relationship between you and that third party. Fraktiq does not assume liability for any errors and omissions and reserves the right to change information, specifications and descriptions of any listed Service. Without derogating from the foregoing, it is agreed and understood that the Site and Service are provided on an "As Is" and "with all faults" basis, and without warranty or condition of any kind, either express or implied.<br><br>
  Fraktiq further makes no representations about the fitness for a particular purpose of any product or service referred to on the Site. Fraktiq makes no warranty that the Service will meet your expectations, or that data and content obtained through the Site and any Service will be accurate, reliable or current, or that the Service will be available on an uninterrupted, secure, or error-free basis. Whilst we will endeavour to correct errors and omissions as quickly as possible after being notified of them, you acknowledge and agree that use of the Service is at your own discretion and sole risk.<br><br>
  <div class="content-sub-title">Prohibition of U.S., HKSAR and PRC Persons</div><br>
  The Company does not offer its tokens or membership interests to persons resident in any jurisdiction where doing so may violate local securities and other laws. Without limiting the foregoing, the Company, its services and membership interests in the form of its tokens (or otherwise) are not being offered in the United States, Hong Kong Special Administrative Region (HKSAR) and People's Republic of China (PRC) or to U.S., HKSAR and PRC persons. Access of any website of the Company by such persons is prohibited.<br><br>
  <div class="content-sub-title">Violations</div><br>
  Please report any violations of the terms of this Agreement by sending email to us.<br><br>
  <div class="content-sub-title">Modifications to the Service and to this Agreement</div><br>
  Fraktiq reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice to you. You agree that Fraktiq shall not be liable to you or to any third party for any modification, suspension or discontinuance of the Service. In addition, Fraktiq may modify the terms of this Agreement at any time. If we do make changes to the terms of this Agreement, we will post the amended version of this Agreement on the Site. We encourage you to frequently visit our Site to determine if any changes to this Agreement have been implemented. For ease of determining if any changes have been made we shall post the date the Agreement was last changed on the top of the page. Your continued use of the Service after any such change constitutes your acceptance of the new terms of this Agreement. If you do not agree to any of these terms, do not use or access (or continue to access) the Service.
  General Terms<br><br>
  This Agreement, together with the Privacy Policy and any other legal notices published by Fraktiq on the Site shall constitute the entire agreement between you and Fraktiq concerning use of the Site and the Service. If any provision of this Agreement is deemed invalid by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of this Agreement, which shall remain in full force and effect. No waiver of any term of this Agreement shall be deemed a further or continuing waiver of such term or any other term, and Fraktiq' failure to assert any right or provision under this Agreement shall not constitute a waiver of such right or provision.<br><br>
  This Agreement is governed by the laws of the Isle of Man, without regards to its conflict of laws principles, and any dispute arising from this Agreement shall be brought exclusively before the courts of the Isle of Man. <br><br>Notwithstanding the foregoing, nothing in this clause shall limit the right of Fraktiq to take proceedings against you in any other court of competent jurisdiction, nor shall the taking of proceedings in any one or more jurisdictions preclude the taking of proceedings in any other jurisdictions, whether concurrently or not, to the extent permitted by the law of such other jurisdiction. You may not transfer or assign your rights or obligations under this Agreement to any third party without the prior written approval of Fraktiq.<br><br>
  Fraktiq may assign this Agreement in whole or in part at its discretion. The English language version of this Agreement shall be the prevailing version in the event of any discrepancy between any translated versions of this Agreement.<br><br>
  `;

  return (
    <section className="tf-section tf-about pb-mobie">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div
              className="content-about m-b60 mobie-40"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <div>
                <h4 className="title">Terms and Conditions</h4>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div
              className="content-about m-b60 mobie-40"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
