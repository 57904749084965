import * as React from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

import { getSupportedLocale } from '@/i18n';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';

import Navbar from '@/components/navbar';
import Footer from '@/components/footer';

function App() {
  const { lang } = useParams();
  const { t, i18n } = useTranslation();
  const supportedLocale = getSupportedLocale(lang);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const exactPath = pathname.replace(lang, supportedLocale);

  useEffect(() => {
    if (lang !== supportedLocale && navigate && exactPath) {
      navigate(exactPath, { replace: true });
    }
  }, [supportedLocale, lang, navigate, exactPath]);

  useEffect(() => {
    if (i18n) {
      if (supportedLocale) {
        i18n.changeLanguage(supportedLocale);
      }
    }
  }, [i18n, supportedLocale]);

  const shareTitle = 'FRAKTIQ // Fractionlized Antique';

  return (
    <div id="wrapper">
      <Helmet>
        <title>{shareTitle}</title>
        <meta property="description" content={t('og_description')} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={shareTitle} />
        <meta property="og:description" content={t('og_description')} />
        <meta property="og:url" content={`https://fraktiq.art${pathname}`} />
        <meta property="og:site_name" content="FRAKTIQ" />
        <meta
          property="og:image"
          itemprop="image"
          content="https://storage.googleapis.com/fraktiq.appspot.com/website/images/og_image.png"
        />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="300" />
        <meta property="og:image:alt" content={shareTitle} />
      </Helmet>
      <Navbar />
      <main className="fraktiq-content">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}

export default App;
