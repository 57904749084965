import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Supporters } from '@/components/supporters';

import './styles.scss';

export function About() {
  const { t } = useTranslation();

  return (
    <>
      <section className="tf-section tf-about style2 pb-mobie">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                className="content-about m-b60 mobie-40"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <div className="tf-title about-us-title">
                  <p className="h8 sub-title">{t('about_us')}</p>
                  <h4 className="title">{t('what_is_fraktiq')}</h4>
                </div>
                <h6>{t('our_mission')}</h6>
                <p className="pd20" dangerouslySetInnerHTML={{ __html: t('our_mission_desc') }} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Supporters />
    </>
  );
}
