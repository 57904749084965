/* eslint-disable no-undef */
import { useEffect, useState, useRef } from 'react';
import { Link, useParams, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HasChildren } from './hasChildren';

import { useWindowSize } from '@/utils/windowSize';

import './navbar.scss';

export default function Navbar() {
  const { t } = useTranslation();
  const { lang } = useParams();

  const [isFixed, setIsFixed] = useState(false);
  const [isSmall, setIsSmall] = useState(false);

  const size = useWindowSize();
  const isMobileMenu = size.width < 992;

  const handleScroll = (event) => {
    if ($(window).scrollTop() > 200) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }

    if ($(window).scrollTop() > 300) {
      setIsSmall(true);
    } else {
      setIsSmall(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // cleanup this component
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [isActive, setIsActive] = useState(false);
  const [isAboutSubmenuActive, setIsAboutSubmenuActive] = useState(false);
  const [isArtifactsSubmenuActive, setIsArtifactsSubmenuActive] =
    useState(false);

  const mobileMenuButtonClick = () => {
    setIsActive(!isActive);
  };
  const mobileMenuItemClick = () => {
    setIsActive(false);
  };

  const localeButtonRef = useRef(null);

  const toggleAboutSubmenuActive = () => {
    setIsAboutSubmenuActive(!isAboutSubmenuActive);
    setIsArtifactsSubmenuActive(false);
    localeButtonRef.current.setLocaleSubmenuActiveToFalse();
  };

  const toggleArtifactsSubmenuActive = () => {
    setIsArtifactsSubmenuActive(!isArtifactsSubmenuActive);
    setIsAboutSubmenuActive(false);
    localeButtonRef.current.setLocaleSubmenuActiveToFalse();
  };

  const [aboutUsHover, setAboutUsHover] = useState(false);
  const [artifactsHover, setArtifactsHover] = useState(false);

  const onAboutUsMouseEnter = () => {
    setAboutUsHover(true);
  };

  const onAboutUsMouseLeave = () => {
    setAboutUsHover(false);
  };

  const onArtifactsMouseEnter = () => {
    setArtifactsHover(true);
  };

  const onArtifactsMouseLeave = () => {
    setArtifactsHover(false);
  };

  return (
    <>
      <header
        id="header_main"
        className={`header ${isFixed ? 'is-fixed' : ''} ${
          isSmall ? 'is-small' : ''
        }`}
      >
        <div className="container">
          <div id="site-header-inner">
            <div className="header__logo">
              <Link
                to={generatePath('/:lang', {
                  lang,
                })}
              >
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/fraktiq.appspot.com/o/website%2Fimages%2Flogo_reversed.png?alt=media&token=87605fe9-adc8-4b05-b2fa-287b164a085e"
                  alt=""
                />
              </Link>
            </div>
            <nav
              id={isMobileMenu ? 'main-nav-mobi' : 'main-nav'}
              className={`main-nav ${
                isMobileMenu ? (isActive ? '' : 'd-none') : ''
              }`}
            >
              <ul id="menu-primary-menu" className="menu">
                <li className="menu-item ">
                  <div
                    className="d-flex align-items-center mobile-menu-item"
                    onMouseEnter={onAboutUsMouseEnter}
                    onMouseLeave={onAboutUsMouseLeave}
                    onClick={toggleAboutSubmenuActive}
                  >
                    <Link to="about" onClick={mobileMenuItemClick}>
                      {t('about_us')}
                    </Link>
                    <HasChildren
                      hover={aboutUsHover}
                      isMobileMenu={isMobileMenu}
                    />
                  </div>
                  <ul
                    className={`sub-menu ${
                      isMobileMenu ? (isAboutSubmenuActive ? '' : 'd-none') : ''
                    }`}
                  >
                    <li className="menu-item" onClick={mobileMenuItemClick}>
                      <Link to="team">{t('fraktiq_team')}</Link>
                    </li>
                    {/* <li className="menu-item" onClick={mobileMenuItemClick}>
                      <Link to="faq">{t('faq')}</Link>
                    </li> */}
                  </ul>
                </li>
                <li className="menu-item" onClick={mobileMenuItemClick}>
                  <Link to="services">{t('services')} </Link>
                </li>
                <li className="menu-item" onClick={mobileMenuItemClick}>
                  <Link to="roadmap">{t('roadmap')} </Link>
                </li>
              </ul>
            </nav>
            <div
              className={`mobile-button d-block d-lg-none ${
                isActive ? 'active' : ''
              }`}
              onClick={mobileMenuButtonClick}
            >
              <span></span>
            </div>
          </div>
        </div>
      </header>
      {isFixed ? <div /> : null}
    </>
  );
}
