import { createSlice } from '@reduxjs/toolkit';

const teamMembers = [
  {
    key: 'team_member_1_key',
    name: 'team_member_1_name',
    description: 'team_member_1_desc',
    image: 'team_member_1_image',
  },
  {
    key: 'team_member_2_key',
    name: 'team_member_2_name',
    description: 'team_member_2_desc',
    image: 'team_member_2_image',
  },
  {
    key: 'team_member_5_key',
    name: 'team_member_5_name',
    description: 'team_member_5_desc',
    image: 'team_member_5_image',
  },
  {
    key: 'team_member_4_key',
    name: 'team_member_4_name',
    description: 'team_member_4_desc',
    image: 'team_member_4_image',
  },
];

const initialState = {
  items: teamMembers,
};

export const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {},
});

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = faqSlice.actions;

export default teamSlice.reducer;
