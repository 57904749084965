import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

export const HasChildren = ({ hover, isMobileMenu }) => {
  return isMobileMenu ? (
    <div className="d-flex justify-content-end align-items-end has-children">
      <FontAwesomeIcon icon={faAngleDown} color={hover ? '#a68149' : 'black'} />
    </div>
  ) : (
    <FontAwesomeIcon icon={faAngleDown} color={hover ? '#a68149' : 'white'} />
  );
};
