import * as React from 'react';
import { useTranslation } from 'react-i18next';

export function TeamMemberItem({ member, onClick }) {
  const { t } = useTranslation();

  return (
    <div
      className="col-sm-6 col-lg-3 col-6 d-flex justify-content-center mb-5"
      data-aos="fade-up"
      data-aos-duration="800"
      onClick={onClick}
    >
      <div>
        <div className="team-box">
          <img src={t(member.image)} alt={t(member.name)} />
          <div className="content">
            <div className="h8">{t(member.name)}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
